import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SbButtonBar } from 'skybase-ui/skybase-components/sb-button-bar/sb-button-bar'
import { SbRadioButton } from 'skybase-ui/skybase-components/sb-radio-button'
import { toCssString } from '@/utils/sanitizer'

export class ButtonBar extends React.Component {
  static defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    className: null,
    value: '',
    options: [],
    disabled: false,
  }

  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.array,
    disabled: PropTypes.bool,
  }

  handleOnChange = event => {
    const { value } = event.target
    const { onChange, onBlur } = this.props
    onChange(value)
    setTimeout(() => {
      onBlur()
    })
  }

  render() {
    const { className, disabled, options, value, ...rest } = this.props
    return (
      <SbButtonBar disabled={disabled} className={classNames(className, `test-current-value-${toCssString(value)}`)}>
        {options.map(({ value: optionValue, description }, index) => (
          <SbRadioButton
            {...rest}
            value={optionValue}
            key={`option-${index}-value-${optionValue}`} // eslint-disable-line react/no-array-index-key
            checked={value === optionValue}
            onClick={this.handleOnChange}
          >
            {description}
          </SbRadioButton>
        ))}
      </SbButtonBar>
    )
  }
}
