/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  sensitivityIsRequired: {
    id: 'Sensitivity is required',
    defaultMessage: 'Sensitivity is required',
  },
  sensitivityShouldBeANumber: {
    id: 'Sensitivity should be a number',
    defaultMessage: 'Sensitivity should be a number',
  },
  physicalRangeIsRequired: {
    id: 'Physical Range is required',
    defaultMessage: 'Physical Range is required',
  },
  physicalRangeShouldBeANumber: {
    id: 'Physical Range should be a number',
    defaultMessage: 'Physical Range should be a number',
  },
  physicalRangeShouldBeBetween: {
    id: 'Physical Range should be between {min} and {max}',
    defaultMessage: 'Physical Range should be between {min} and {max}',
  },
  physicalRangeShouldGreaterThan: {
    id: 'Physical Range should be greater than or equal to {otherValue} {unit}',
    defaultMessage: 'Physical Range should be greater than or equal to {otherValue} {unit}',
  },
  customUnitIsRequired: {
    id: 'Custom Unit is required',
    defaultMessage: 'Custom Unit is required',
  },
  customUnitMustBeAtMostNCharactersLong: {
    id: 'customUnitMustBeAtMostNCharactersLong',
    defaultMessage: 'Custom Unit must be at most {otherValue} characters long',
  },
  zeroDeviationIsRequired: {
    id: 'Zero Deviation is required',
    defaultMessage: 'Zero Deviation is required',
  },
  zeroDeviationShouldBeANumber: {
    id: 'Zero Deviation should be a number',
    defaultMessage: 'Zero Deviation should be a number',
  },
  physicalRangeShouldBeLowerThanOrEqualToOtherValue: {
    id: 'Physical Range should be lower than or equal to {otherValue} {unit}',
    defaultMessage: 'Physical Range should be lower than or equal to {otherValue} {unit}',
  },
  signalNameIsRequired: {
    id: 'Signal Name is required',
    defaultMessage: 'Signal Name is required',
  },
  physicalRangeFromIsRequired: {
    id: 'Physical Range From is required',
    defaultMessage: 'Physical Range From is required',
  },
  physicalRangeFromShouldBeANumber: {
    id: 'Physical Range From should be a number',
    defaultMessage: 'Physical Range From should be a number',
  },
  physicalRangeFromShouldBeGreaterThanOrEqualToOtherValue: {
    id: 'Physical Range From should be greater than or equal to {otherValue} {unit}',
    defaultMessage: 'Physical Range From should be greater than or equal to {otherValue} {unit}',
  },
  physicalRangeFromShouldBeLowerThanOrEqualToOtherValue: {
    id: 'Physical Range From should be lower than or equal to {otherValue} {unit}',
    defaultMessage: 'Physical Range From should be lower than or equal to {otherValue} {unit}',
  },
  physicalRangeToIsRequired: {
    id: 'Physical Range To is required',
    defaultMessage: 'Physical Range To is required',
  },
  physicalRangeToShouldBeANumber: {
    id: 'Physical Range To should be a number',
    defaultMessage: 'Physical Range To should be a number',
  },
  physicalRangeToShouldBeGreaterThanOrEqualToOtherValue: {
    id: 'Physical Range To should be greater than or equal to {otherValue} {unit}',
    defaultMessage: 'Physical Range To should be greater than or equal to {otherValue} {unit}',
  },
  physicalRangeToShouldBeLowerThanOrEqualToOtherValue: {
    id: 'Physical Range To should be lower than or equal to {otherValue} {unit}',
    defaultMessage: 'Physical Range To should be lower than or equal to {otherValue} {unit}',
  },
  physicalRangeFromShouldBeLowerThanPhysicalRangeTo: {
    id: 'Physical Range From should be lower than Physical Range To',
    defaultMessage: 'Physical Range From should be lower than Physical Range To',
  },
  physicalRangeFromShouldBeHigherThanPhysicalRangeTo: {
    id: 'channelValidator.physicalRangeFromShouldBeHigherThanPhysicalRangeTo',
    defaultMessage: 'Physical Range From should be higher than Physical Range To',
  },
  upperCuttoffFrequencyValidationMsg: {
    id: 'Upper cuttoff frequency should be greater than lower cuttoff frequency',
    defaultMessage: 'Upper cuttoff frequency should be greater than lower cuttoff frequency',
  },
  offsetIsRequired: {
    id: 'Offset is required',
    defaultMessage: 'Offset is required',
  },
  offsetShouldBeANumber: {
    id: 'Offset should be a number',
    defaultMessage: 'Offset should be a number',
  },
  sensitivityShouldNotBeZero: {
    id: 'Sensitivity should not be 0',
    defaultMessage: 'Sensitivity should not be 0',
  },
  sensitivityShouldBeLowerThanOrEqualToOtherValue: {
    id: 'Sensitivity should be lower than or equal to {otherValue}',
    defaultMessage: 'Sensitivity should be lower than or equal to {otherValue}',
  },
  sensitivityShouldBeHigherThanOrEqualToOtherValue: {
    id: 'Sensitivity should be higher than or equal to {otherValue}',
    defaultMessage: 'Sensitivity should be higher than or equal to {otherValue}',
  },
  zeroPointCorrectionMustBeLesserThanOtherValue: {
    id: 'Zero Point Correction must be lesser than {otherValue}',
    defaultMessage: 'Zero Point Correction must be lesser than {otherValue}',
  },
  pulseFactorIsRequired: {
    id: 'Pulse factor is required',
    defaultMessage: 'Pulse factor is required',
  },
  pulseFactorShouldBeANumber: {
    id: 'Pulse factor should be a number',
    defaultMessage: 'Pulse factor should be a number',
  },
  pulseFactorShouldNotBeZero: {
    id: 'Pulse factor should not be 0',
    defaultMessage: 'Pulse factor should not be 0',
  },
  minimalSupportedFrequencyIsFrequency: {
    id: 'Minimal supported frequency is {frequency}',
    defaultMessage: 'Minimal supported frequency is {frequency}',
  },
  maximalSupportedFrequencyIsFrequency: {
    id: 'Maximal supported frequency is {frequency}',
    defaultMessage: 'Maximal supported frequency is {frequency}',
  },
  minimalSupportedQFactorIsQFactor: {
    id: 'Minimal supported Q-Factor is {qFactor}',
    defaultMessage: 'Minimal supported Q-Factor is {qFactor}',
  },
  maximalSupportedQFactorIsQFactor: {
    id: 'Maximal supported Q-Factor is {qFactor}',
    defaultMessage: 'Maximal supported Q-Factor is {qFactor}',
  },
  timeBaseIsRequired: {
    id: 'Time base is required',
    defaultMessage: 'Time base is required',
  },
  timeBaseShouldBeNumber: {
    id: 'Time base should be a number',
    defaultMessage: 'Time base should be a number',
  },
  timeBaseGreaterEqualThan: {
    id: 'Time base should be greater than or equal to {otherValue}',
    defaultMessage: 'Time base should be greater than or equal to {otherValue}',
  },
  timeBaseLesserEqualThan: {
    id: 'Time base should be lesser or equal than {otherValue}',
    defaultMessage: 'Time base should be lesser or equal than {otherValue}',
  },
  sensitivityUnitIsRequired: {
    id: 'Sensitivity unit is required',
    defaultMessage: 'Sensitivity unit is required',
  },
  physicalUnitIsRequired: {
    id: 'Physical unit is required',
    defaultMessage: 'Physical unit is required',
  },
  switchingLevelLowerBoundNeedsToBeDefined: {
    id: 'Switching level lower bound needs to be defined',
    defaultMessage: 'Switching level lower bound needs to be defined',
  },
  switchingLevelUpperBoundNeedsToBeDefined: {
    id: 'Switching level upper bound needs to be defined',
    defaultMessage: 'Switching level upper bound needs to be defined',
  },
  switchingLevelLowerBoundNeedsToBeLowerThenUpperBound: {
    id: 'Switching level lower bound needs to be lower then upper bound',
    defaultMessage: 'Switching level lower bound needs to be lower then upper bound',
  },
  switchingLevelLowerBoundNeedsToBeAtLeastLowerBound: {
    id: 'Switching level lower bound needs to be at least {lowerBound} {unit}',
    defaultMessage: 'Switching level lower bound needs to be at least {lowerBound} {unit}',
  },
  switchingLevelLowerBoundNeedsToBeAtMostUpperBound: {
    id: 'Switching level lower bound needs to be at most {upperBound} {unit}',
    defaultMessage: 'Switching level lower bound needs to be at most {upperBound} {unit}',
  },
  switchingLevelUpperBoundNeedsToBeAtLeastLowerBound: {
    id: 'Switching level upper bound needs to be at least {lowerBound} {unit}',
    defaultMessage: 'Switching level upper bound needs to be at least {lowerBound} {unit}',
  },
  switchingLevelUpperBoundNeedsToBeAtMostUpperBound: {
    id: 'Switching level upper bound needs to be at most {upperBound} {unit}',
    defaultMessage: 'Switching level upper bound needs to be at most {upperBound} {unit}',
  },
  signalTypeIsRequired: {
    id: 'channelValidator.signalTypeIsRequired',
    defaultMessage: 'Signal Type is required',
  },
  sensorTypeIsRequired: {
    id: 'channelValidator.sensorTypeIsRequired',
    defaultMessage: 'Sensor Type is required',
  },
  sensorConnectorTypeIsRequired: {
    id: 'channelValidator.sensorConnectorTypeIsRequired',
    defaultMessage: 'Sensor Connector Type is required',
  },
  sensorTypeDetailIsRequired: {
    id: 'channelValidator.sensorTypeDetailIsRequired',
    defaultMessage: 'Sensor Type Detail is required',
  },
  physicalQuantityIsRequired: {
    id: 'channelValidator.physicalQuantityIsRequired',
    defaultMessage: 'Physical Quantity is required',
  },
})
