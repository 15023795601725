import React from 'react'
import { escapeRegExp } from 'lodash'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint/sb-hint'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { SbDropdown } from 'skybase-ui/skybase-components/sb-dropdown'
import { horizontalPosition, verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { copyToClipboard } from 'skybase-ui/skybase-core/utils/copy-to-clipboard'
import { showInfoToast } from '@/common/services/show-toast'
import { GREEN, RED, StatusBullet } from '@/common/status-bullet'
import { getDropdownLabelByValue } from '@/utils'
import { ReactComponent as UnitIcon } from '@/fleet-configuration/icons/Unit-Icon.svg'
import { ReactComponent as LabampIcon } from '@/fleet-configuration/icons/LabampIcon.svg'
import { ReactComponent as ForcePlateIcon } from '@/fleet-configuration/icons/ForcePlate.svg'
import { ReactComponent as StartBlockIcon } from '@/fleet-configuration/icons/KiSprint.svg'
import { ReactComponent as DaqBoxIcon } from '@/fleet-configuration/icons/DaqBox.svg'
import { DEVICE_TYPE } from '@/fleet-configuration/page-components/devices-table-renderer/devices-table-renderer-constants'
import { messages as t } from './devices-table-renderer-i18n'
import './devices-table-renderer.scss'

const { KIDAQ, LABAMP, FORCE_PLATE, BIO_SYNC_BOX, BIO_START_BLOCK, BIO_DAQ_BOX } = DEVICE_TYPE

class _devicesTableRenderer {
  commonTableColumns = null

  commonCellFormatterFields = null

  initialize(_, onFilterValueChange) {
    this.formatMessage = _
    this.commonTableColumns = [
      { name: 'name', label: _(t.name) },
      { name: 'status', label: _(t.status) },
      { name: 'serialNumber', label: _(t.serialNumber) },
      { name: 'modelNumber', label: _(t.typeNumber) },
      { name: 'deviceType', label: _(t.deviceType), cellsClassName: 'device-type' },
      { name: 'daq', label: _(t.daq) },
    ]
    this.commonCellFormatterFields = this.commonTableColumns.map(col => col.name)
    this.onFilterValueChange = onFilterValueChange
  }

  cellFormatter = (value, key, row) => {
    if (key === 'name') {
      return this.renderName(value, row)
    }
    if (key === 'status') {
      return this.renderStatusCell(value)
    }
    if (key === 'serialNumber') {
      return this.renderSerialNumber(value)
    }
    if (key === 'modelNumber') {
      return this.renderModelNumber(value)
    }
    if (key === 'deviceType') {
      return this.renderDeviceType(value)
    }
    if (key === 'daq') {
      return this.renderDAQ(value)
    }
    return value
  }

  applyFilterOnDevice = (filterValues, device) => {
    if (
      ['name', 'serialNumber', 'modelNumber'].some(
        field => filterValues[field] && !new RegExp(escapeRegExp(filterValues[field]), 'i').test(device[field]),
      )
    ) {
      return false
    }
    if (filterValues.status) {
      if (
        (['online', 'discovered'].includes(device.status) && filterValues.status === 'offline') ||
        (device.status === 'offline' && filterValues.status === 'online')
      ) {
        return false
      }
    }
    if (filterValues.deviceType && device.deviceType !== filterValues.deviceType) {
      return false
    }
    if (
      filterValues.daq &&
      ((device.daq && filterValues.daq === 'false') || (!device.daq && filterValues.daq === 'true'))
    ) {
      return false
    }
    return true
  }

  filterCellFormatter = (filterValues, key) => {
    const { formatMessage: _ } = this
    if (['name', 'serialNumber', 'modelNumber'].includes(key)) {
      return <SbTextbox onChange={evt => this.onFilterValueChange(key, evt.target.value)} value={filterValues[key]} />
    }
    if (key === 'status') {
      const statusOptions = [
        { value: '', label: _(t.all) },
        { value: 'online', label: _(t.online) },
        { value: 'offline', label: _(t.offline) },
      ]
      return (
        <SbDropdown
          onChange={value => this.onFilterValueChange(key, value)}
          value={filterValues[key]}
          title={getDropdownLabelByValue(statusOptions, filterValues[key])}
          items={statusOptions}
        />
      )
    }
    if (key === 'deviceType') {
      const deviceTypeOptions = [
        { value: '', label: _(t.all) },
        ...Object.values(DEVICE_TYPE).map(type => ({ value: type, label: _(t[type]) })),
      ]
      return (
        <SbDropdown
          onChange={value => this.onFilterValueChange(key, value)}
          value={filterValues[key]}
          items={deviceTypeOptions}
          title={getDropdownLabelByValue(deviceTypeOptions, filterValues[key])}
        />
      )
    }
    if (key === 'daq') {
      const daqOptions = [
        { value: '', label: _(t.all) },
        { value: 'true', label: _(t.yes) },
        { value: 'false', label: _(t.no) },
      ]
      return (
        <SbDropdown
          onChange={value => this.onFilterValueChange(key, value)}
          value={filterValues[key]}
          items={daqOptions}
          title={getDropdownLabelByValue(daqOptions, filterValues[key])}
        />
      )
    }
    return null
  }

  handleCopyClick = value => {
    const { formatMessage: _ } = this
    if (copyToClipboard(value)) {
      showInfoToast(_(t.copiedText, { text: value }))
    }
  }

  renderName(value, row) {
    if (row.isDirty) {
      const { formatMessage: _ } = this
      return (
        <SbHint
          wrapperClassName="status-dirty"
          position={{ horizontal: horizontalPosition.RIGHT, vertical: verticalPosition.TOP }}
          hintData={_(t.configurationIsNotWrittenToDevice)}
        >
          <span>{value}</span>
        </SbHint>
      )
    }
    return value
  }

  renderStatusCell(value) {
    const { formatMessage: _ } = this
    const isOnline = ['online', 'discovered'].includes(value)
    return <StatusBullet status={isOnline ? GREEN : RED}>{isOnline ? _(t.online) : _(t.offline)}</StatusBullet>
  }

  renderSerialNumber(value) {
    return (
      <div className="copy-box">
        {value}
        {value && (
          <div className="box m-l-10" onClick={this.handleCopyClick.bind(this, value)}>
            <i className="sbi-copy" />
          </div>
        )}
      </div>
    )
  }

  renderModelNumber(value) {
    return (
      <div className="copy-box">
        {value}
        {value && (
          <div className="box m-l-10" onClick={this.handleCopyClick.bind(this, value)}>
            <i className="sbi-copy" />
          </div>
        )}
      </div>
    )
  }

  renderDeviceType(value) {
    const { formatMessage: _ } = this
    let icon
    switch (value) {
      case KIDAQ:
        icon = <UnitIcon />
        break
      case LABAMP:
        icon = <LabampIcon />
        break
      case FORCE_PLATE:
        icon = <ForcePlateIcon />
        break
      case BIO_SYNC_BOX:
        icon = <LabampIcon />
        break
      case BIO_DAQ_BOX:
        icon = <DaqBoxIcon />
        break
      case BIO_START_BLOCK:
        icon = <StartBlockIcon />
        break
      default:
        icon = null
    }
    const textVersion = value ? _(t[value]) : '-'
    return (
      <>
        <span className="device-type-icon">{icon}</span>
        {textVersion}
      </>
    )
  }

  renderDAQ(value) {
    if (value) {
      return <span className="sbi-accept-kids" />
    }
    return ''
  }
}

export const devicesTableRenderer = new _devicesTableRenderer()
